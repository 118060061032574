@import url('https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap');

$bodyfont:'Almarai', sans-serif;
$headingfont:'Almarai', sans-serif;

/*Theme Colors*/

$sidebar: #fff;
$sidebar-white: #e5edef;
$sidebar-alt:#f2f6f8;
$bodycolor: #eef5f9;
$headingtext: #212529;
$bodytext: #444447;
$sidebar-text: #607d8b;
$sidebar-icons: #99abb4 ;

$light-text: #a6b7bf;
$themecolor-alt: #26c6da;
$themecolor-dark: #028ee1;

$danger: #DC3545 !default;
$success: #23A486 !default;
$warning: #FFCD39 !default;
$primary: #0C76CF !default;
$info: #0DCAF0 !default;
$bg-color: #F8F8F8;

$topbar: $primary;
$themecolor: $primary;

$red: #DC3545 !default;
$green: #23A486 !default;
$yellow: #FFCD39 !default;
$blue: #0C76CF !default;
$cyan: #0DCAF0 !default;
$secondary: #6E6E96;
$inverse: #2f3d4a;
$muted: #495057cf;
$dark: #58595b;
$light: #F2F4F8;
$extra-light: #ebf3f5;
$bglight:rgba(0, 0, 0, 0.02);
$red-light: #FF5686;
$red-dark: #B0002C;
$red-pink: #DA2969;
$purple-light: #AE608C;

/*Light colors*/
$light-danger: #f9e7eb;
$light-success: #e8fdeb;
$light-warning: #fff8ec;
$light-primary: #f1effd;
$light-info: #cfecfe;
$light-inverse: #f6f6f6;
$light-megna: #e0f2f4;

$danger-dark: #e6294b;
$success-dark: #1eacbe;
$warning-dark: #e79c18;
$primary-dark: #0a5ea5;
$info-dark: #028ee1;
$inverse-dark: #232a37;
$dark-transparent:rgba(0, 0, 0, 0.05);

$colors: (
  "blue":       $blue,
  "red":        $red,
  "yellow":     $yellow,
  "green":      $green,
  "cyan":       $cyan,
  "gray":       $secondary,
);

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger
);

$info-shadow: 0 2px 2px 0 rgba(66,165,245,.14), 0 3px 1px -2px rgba(66,165,245,.2), 0 1px 5px 0 rgba(66,165,245,.12);
$info-shadow-hover:0 14px 26px -12px rgba(23,105,255,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(23,105,255,.2);

$warning-shadow:0 2px 2px 0 rgba(248,194,0,.14), 0 3px 1px -2px rgba(248,194,0,.2), 0 1px 5px 0 rgba(248,194,0,.12);
$warning-shadow-hover:0 14px 26px -12px rgba(248,194,0,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(248,194,0,.2);

$danger-shadow:0 2px 2px 0 rgba(239,83,80,.14), 0 3px 1px -2px rgba(239,83,80,.2), 0 1px 5px 0 rgba(239,83,80,.12);
$danger-shadow-hover:0 14px 26px -12px rgba(239,83,80,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(239,83,80,.2);

$success-shadow:0 2px 2px 0 rgba(40,190,189,.14), 0 3px 1px -2px rgba(40,190,189,.2), 0 1px 5px 0 rgba(40,190,189,.12);
$success-shadow-hover:0 14px 26px -12px rgba(40,190,189,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(40,190,189,.2);

$primary-shadow:0 2px 2px 0 rgba(116,96,238,.14), 0 3px 1px -2px rgba(116,96,238,.2), 0 1px 5px 0 rgba(116,96,238,.12);
$primary-shadow-hover:0 14px 26px -12px rgba(116,96,238,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(116,96,238,.2);

$default-shadow:0 2px 2px 0 rgba(169,169,169,.14), 0 3px 1px -2px rgba(169,169,169,.2), 0 1px 5px 0 rgba(169,169,169,.12);
$default-shadow-hover:0 14px 26px -12px rgba(169,169,169,.42), 0 4px 23px 0 rgba(0,0,0,.12), 0 8px 10px -5px rgba(169,169,169,.2);

/*Normal Color*/
$white: #ffffff;
$purple: #7460ee;
$megna: #00897b;

/*Extra Variable*/
$rgt: right;
$lft: left;
$border: rgba(120, 130, 140, 0.13);
$table-border:#f3f1f1;
$card-brd:#d7dfe3;
$dark-text: #848a96;
$radius: 20px;
$form-brd: #b1b8bb;

/*Preloader*/

.preloader{
    width: 100%;
    height: 100%;
    top:0px;
    position: fixed;
    z-index: 99999;
    background: #fff;
    .cssload-speeding-wheel{
        position: absolute;
        top: calc(50% - 3.5px);
        left: calc(50% - 3.5px);
    }
}

:root {
  --bs-blue: #0C76CF;
  --bs-indigo: #6610f2;
  --bs-purple: #7460ee;
  --bs-pink: #d63384;
  --bs-red: #DC3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #FFCD39;
  --bs-green: #23A486;
  --bs-teal: #20c997;
  --bs-cyan: #0DCAF0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6E6E96;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #E9ECEF;
  --bs-gray-200: #F2F4F8;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6E6E96;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0C76CF;
  --bs-secondary: #6e6e96;
  --bs-success: #23a486;
  --bs-info: #0dcaf0;
  --bs-warning: #ffcd39;
  --bs-danger: #dc3545; 
  --bs-light: #e9ecef;
  --bs-dark: #343A40;
  --bs-primary-rgb: 12, 118, 207; 
  --bs-secondary-rgb: 110, 110, 150;
  --bs-success-rgb: 35, 164, 134;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 205, 57;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 233, 236, 239;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --border-radius: 20px !important;
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0C76CF;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-bg-color: #F8F8F8;
}
