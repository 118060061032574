

/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import 'variable';

@import "node_modules/bootstrap/scss/bootstrap.scss";

@import 'app';
@import 'bootstrap-overwrite';
@import 'pages';
@import 'sidebar';
@import 'widgets';
@import 'grid';
@import 'responsive';
@import 'spinners.scss';
@import 'animate.css';
@import 'theme-settings.scss';
// color css
@import 'colors/blue-dark';
@import 'colors/default-dark';
@import 'colors/red-dark';
@import 'colors/green-dark';
@import 'colors/purple-dark';
@import 'colors/megna-dark';
@import 'colors/megna';
@import 'colors/blue';
@import 'colors/red';
@import 'colors/green';
@import 'colors/purple';
@import 'colors/default';
// dark theme
@import 'dark';

@import 'overrides.scss';