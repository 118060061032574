.btn-info {
    color: white !important;
}

.btn-danger {
    color: white !important;
}

.btn-success {
    color: white !important;
}

.btn-outline-info:hover {
    color: white !important;
}

.btn-outline-danger:hover {
    color: white !important;
}

.btn-outline-success:hover {
    color: white !important;
}
