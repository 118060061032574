/*
Template Name: Admin Press Admin
Author: Theme designer
Email: niravjoshi87@gmail.com
File: scss
*/


/**
 * Table Of Content
 *
 *  1. Globals
 *  2. Headers
 *  3. Navigations
 *  4. Banners
 *  5. Footers
 *  6. Posts
 *  7. Widgets
 *  8. Custom Templates
 */


/*******************
Global Styles
*******************/

* {
    outline: none;

}

::-moz-selection {
    background: $primary;
    color: #ffffff;
}

::selection {
    background: $primary;
    color: #ffffff;
}

body {
    background: $sidebar;
    font-family: $bodyfont;
    margin: 0;
    overflow-x: hidden;
    color: $bodytext;
    font-weight: 300;
}

html {
    position: relative;
    min-height: 100%;
    background: $white;
}


*::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: #f1f1f1;
}

*::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
}

*::-webkit-scrollbar-corner {
    background: #f1f1f1;
}

a:hover,
a:focus {
    text-decoration: none;
}

a.link {
    color: $headingtext;
    &:hover,
    &:focus {
        color: $themecolor;
    }
}

ol, ul {
    padding-left: 0;
    padding-right: 0;
}

.img-responsive {
    width: 100%;
    height: auto;
    display: inline-block;
}

.img-rounded {
    border-radius: $radius;
}

html body .mdi:before,
html body .mdi-set {
    line-height: initial;
}

/*******************
Headings
*******************/

h1,
h2,
h3,
h4,
h5,
h6 {
    color: $headingtext;
    font-family: $headingfont;
    font-weight: 400;
}

.font-48 {
    font-size: 48px;
}

.color-initial {
    color: initial;
}

.color-initial:hover {
    color: $primary;
}

.box {
    border-radius: $radius;
    padding: 10px;
}

.btn-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.11);
}

.bg-purple {
    background-color: var(--bs-purple);
}

.bg-dark-red {
    background-color: $red-dark;
}

.bg-danger-light {
    background-color: tint-color($danger, 60%);
}

.bg-warning-light {
    background-color: tint-color($warning, 60%);
}

.bg-success-light {
    background-color: tint-color($success, 50%);
}

.bg-info-light {
    background-color: tint-color($info, 60%);
}

.bg-primary-light {
    background-color: tint-color($primary, 60%);
}

.bg-soft-primary {
    background-color: tint-color($primary, 80%) !important;
    border: 1px solid tint-color($primary, 60%) !important;
    color: $primary !important;
}

.bg-light {
    background-color: $light;
    color: black !important;
}

.btn-outline-white {
    border: 1px solid;
    color: white;
}

.btn-outline-white:hover {
    border: 1px solid;
    color: black;
    background-color: white;
}

.white-bg {
    background-color: white;
}

.bg-new {
    background-color: $info;
}

.bg-purchased {
    background-color: $success;
}

.bg-followUp {
    background-color: #50C82E;
}

.bg-busy {
    background-color: $warning;
    color: black;
}

.bg-answered {
    background-color: $primary;
}

.bg-notPickup {
    background-color: #FF7272;
}

.bg-notInterested {
    background-color: #ED8E00;
}

.bg-outOfService {
    background-color: $danger;
}

.bg-wrongNumber {
    background-color: #FFE951;
    color: black;
}

// Text colors

.text-followUp {
    color: #50C82E;
}

// Border color

.border-primary {
    border: 1px solid $primary;
}
.border-info {
    border: 1px solid $info;
}
.border-success {
    border: 1px solid $success;
}
.border-danger {
    border: 1px solid $danger;
}
.border-secondary {
    border: 1px solid $secondary;
}

.no-wrap {
    td,
    th {
        white-space: nowrap;
    }
}

.w-100px {
    width: 100px !important;
}

.h-250px {
    height: 250px;
}

.h-300px {
    height: 300px;
}

/*******************
Blockquote
*******************/

html body blockquote {
    border-left: 5px solid $themecolor;
    border: 1px solid $border;
    padding: 15px;
}

.clear {
    clear: both;
}

ol li {
    margin: 5px 0;
}

/*******************
Nav
*******************/

.nav-pills {
    margin-bottom: 15px;
    background: #f4f6fa;
}

.nav-pills .nav-link {
    border: 1px solid var(--bs-primary);
    font-size: 14px;
    color: var(--bs-primary) !important;
    padding: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.nav-pills .nav-link.active {
    background-color: $primary !important;
    color: #ffffff !important;
}

.nav-pills .nav-link.active .tab-para {
    color: rgba(255, 255, 255, 0.65) !important;
}

.nav-pills a .title {
    font-size: 17px;
    font-weight: 700;
}

/*******************
font weight
*******************/

html body {
    .font-16 {
        font-size: 16px;
    }
    .font-14 {
        font-size: 14px;
    }
    .font-10 {
        font-size: 10px;
    }
    .font-12 {
        font-size: 12px;
    }
    .font-18 {
        font-size: 18px;
    }
    .font-20 {
        font-size: 20px;
    }
    .font-24 {
        font-size: 24px;
    }
    .font-25 {
        font-size: 25px;
    }
    .font-30 {
        font-size: 30px;
    }
}

/*******************
Tag
*******************/

.p-tag-success {
    background: $success !important;
}

.p-tag-danger {
    background: $danger !important;
}

.p-tag-warning {
    color: white !important;
    background: $warning !important;
}

.p-tag-info {
    background: $info !important;
}

.p-tag-primary {
    background: $primary !important;
}

p-tag.purple .p-tag {
    background: $purple !important;
}
p-tag.secondary .p-tag {
    background: $secondary !important;
}
p-tag.darkRed .p-tag {
    background: $red-dark !important;
}


/*******************
Wave Effects
*******************/

.waves-effect {
    position: relative;
    cursor: pointer;
    display: inline-block;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    z-index: 1;
    will-change: opacity, transform;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    -o-transition: all 0.1s ease-out;
    -ms-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out;
    .waves-ripple {
        position: absolute;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        margin-top: -10px;
        margin-left: -10px;
        opacity: 0;
        background: rgba(0, 0, 0, 0.2);
        -webkit-transition: all 0.7s ease-out;
        -moz-transition: all 0.7s ease-out;
        -o-transition: all 0.7s ease-out;
        -ms-transition: all 0.7s ease-out;
        transition: all 0.7s ease-out;
        -webkit-transition-property: -webkit-transform, opacity;
        -moz-transition-property: -moz-transform, opacity;
        -o-transition-property: -o-transform, opacity;
        transition-property: transform, opacity;
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
        transform: scale(0);
        pointer-events: none;
    }
}

.waves-effect.waves-light {
    .waves-ripple {
        background-color: rgba(255, 255, 255, 0.45);
    }
}

.waves-effect.waves-red {
    .waves-ripple {
        background-color: rgba(244, 67, 54, 0.7);
    }
}

.waves-effect.waves-yellow {
    .waves-ripple {
        background-color: rgba(255, 235, 59, 0.7);
    }
}

.waves-effect.waves-orange {
    .waves-ripple {
        background-color: rgba(255, 152, 0, 0.7);
    }
}

.waves-effect.waves-purple {
    .waves-ripple {
        background-color: rgba(156, 39, 176, 0.7);
    }
}

.waves-effect.waves-green {
    .waves-ripple {
        background-color: rgba(76, 175, 80, 0.7);
    }
}

.waves-effect.waves-teal {
    .waves-ripple {
        background-color: rgba(0, 150, 136, 0.7);
    }
}

html body .waves-notransition {
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    -ms-transition: none;
    transition: none;
}

.waves-circle {
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    text-align: center;
    width: 2.5em;
    height: 2.5em;
    line-height: 2.5em;
    border-radius: 50%;
}

.waves-input-wrapper {
    border-radius: 0.2em;
    vertical-align: bottom;
    .waves-button-input {
        position: relative;
        top: 0;
        left: 0;
        z-index: 1;
    }
}

.waves-block {
    display: block;
}

/*******************
Notify
*******************/

.notify {
    position: relative;
    top: -22px;
    right: -9px;
    .heartbit {
        position: absolute;
        top: -20px;
        right: -4px;
        height: 25px;
        width: 25px;
        z-index: 10;
        border: 5px solid $danger;
        border-radius: 70px;
        animation: heartbit 1s ease-out;
        -moz-animation-iteration-count: infinite;
        -o-animation: heartbit 1s ease-out;
        -o-animation-iteration-count: infinite;
        -webkit-animation: heartbit 1s ease-out;
        -webkit-animation-iteration-count: infinite;
        animation-iteration-count: infinite;
    }
    .point {
        width: 6px;
        height: 6px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        background-color: $danger;
        position: absolute;
        right: 6px;
        top: -10px;
    }
}

@keyframes heartbit {
    0% {
        -moz-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -moz-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -moz-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -moz-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -moz-transform: scale(1);
        opacity: 0.0;
    }
}

@-webkit-keyframes heartbit {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }
    25% {
        -webkit-transform: scale(0.1);
        opacity: 0.1;
    }
    50% {
        -webkit-transform: scale(0.5);
        opacity: 0.3;
    }
    75% {
        -webkit-transform: scale(0.8);
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0.0;
    }
}

/*******************
File Upload
******************/

.fileupload {
    overflow: hidden;
    position: relative;
    input.upload {
        cursor: pointer;
        filter: alpha(opacity=0);
        font-size: 20px;
        margin: 0;
        opacity: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
    }
}

/*******************
Mega menu
******************/

.mega-dropdown {
    position: static!important;
    width: 100%;

    .dropdown-menu {
        width: 100%;
        padding: 30px;
        margin-top: 0px;
        left:0px!important;
    }
    ul {
        padding: 0px;

        li {
            list-style: none;
        }
    }
    .carousel-item .container {
        padding: 0px;
    }
    .nav-accordion {
        .card {
            margin-bottom: 1px;
        }
        .card-header {
            background: $white;
            h5 {
                margin: 0px;
                a {
                    text-decoration: none;
                    color: $bodytext;
                }
            }
        }
    }
}

/*******************
List-style-none
******************/

ul.list-style-none {
    margin: 0px;
    padding: 0px;
    li {
        list-style: none;
        a {
            color: $bodytext;
            padding: 8px 0px;
            display: block;
            text-decoration: none;
            &:hover {
                color: $themecolor;
            }
        }
    }
}

/*******************
dropdown-item
******************/

.dropdown-item {
    padding: 8px 1rem;
    color: $bodytext;
}

/*******************
Custom-select
******************/

.custom-select {
    background: url(../../assets/images/custom-select.png) left .75rem center no-repeat;
}

/*******************
textarea
******************/

textarea {
    resize: none;
}

/*******************
Form-control
******************/

.form-control {
    color: $bodytext;
    max-height: 42px;
    display: initial;
}

.form-control-sm {
    min-height: 20px;
}

.form-control:disabled,
.form-control[readonly] {
    opacity: 0.7;
}

.custom-control-input:focus~.custom-control-indicator {
    box-shadow: none;
}

.custom-control-input:checked~.custom-control-indicator {
    background-color: $success;
}

form label {
    font-weight: 400;
    padding-bottom: 0.5rem;
}

.form-group {
    margin-bottom: 25px;
}

.form-horizontal label {
    margin-bottom: 0px;
}

.form-control-static {
    padding-top: 0px;
}

.form-bordered .form-group {
    border-bottom: 1px solid $border;
    padding-bottom: 20px;
}


/*******************
Layouts
*****************/

/*Card no borders*/

.card-no-border {
    .card {
        .card-header:first-child {
            border-radius: $radius $radius 0 0;
        }
        .card-body {
            border-radius: $radius;
        }
        border: 0px;
        border-radius: $radius;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);
    }
}

.card-no-border .shadow-none {
    box-shadow: none;
}

.card-outline-danger,
.card-outline-info,
.card-outline-warning,
.card-outline-success,
.card-outline-primary {
    background: $white;
}

.card-fullscreen {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    overflow: auto;
}

/*******************/
/*widgets -app*/
/*******************/

.css-bar:after {
    z-index: 1;
}

.css-bar>i {
    z-index: 10;
}

/*******************/
/*single column*/
/*******************/

.single-column {
    .left-sidebar {
        display: none;
    }
    .page-wrapper {
        margin-right: 0px;
    }
}

.fix-width {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

/*******************/
/*card-default*/
/*******************/

.card-default {
    .card-header {
        background: $white;
        border-bottom: 0px;
    }
}

/*******************/
/*pace-js*/
/*******************/

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: $danger;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

.progress{
    height:auto;
    min-height:6px;
}

.no-control-indicator {
    .carousel-indicators,
    .carousel-control-prev,
    .carousel-control-next {
        display: none;
    }
}
dp-date-picker > div:nth-child(2){
    top: initial !important;
    left: initial !important;
}

.pointer {
    cursor: pointer;
}

.toast-container {
    z-index: 10000 !important;
    position: fixed !important;
}

.p-input-icon-left>em, .p-input-icon-left>.p-icon-wrapper, .p-input-icon-right>em, .p-input-icon-right>.p-icon-wrapper {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
}

.p-input-icon-left > .p-icon-wrapper.p-icon, .p-input-icon-left > em:first-of-type {
    left: 0.5rem;
    color: #677591;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
    text-align: start;
    border-width: 1px 0 1px 1px;
    border-color:#F2F4F8;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
    text-align: start;
    border-width: 1px 0 1px 1px;
    border-color:#F2F4F8;
}

.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td:last-child {
    text-align: start;
    border-width: 1px 0 1px 0;
    border-color:#F2F4F8;
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th:last-child {
    text-align: start;
    border-width: 1px 0 1px 0;
    border-color:#F2F4F8;
}

.p-datatable .p-datatable-header, .p-datatable .p-datatable-thead>tr>th {
    background: #eaeff3;
}

.p-dialog {
    padding-bottom: 1rem;
    background: white;
    border-radius: var(--border-radius);
}

.p-dialog .p-dialog-header {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
}

.p-dialog .p-dialog-content:last-child {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
}

.p-dialog .p-dialog-footer {
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    text-align: end;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
    border-top-color: #ffffff;
}

.p-tooltip .p-tooltip-text {
    background: #ffffff;
    color: $bodytext;
    padding: .5rem;
    box-shadow: 0 2px 4px -1px #e9ecef, 0 4px 5px #e9ecef, 0 1px 10px #e9ecef;
    border-radius: 5px;
}

.secondary.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $secondary !important;
}

.secondary.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $secondary !important;
}

.secondary.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $secondary !important;
}

.secondary.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $secondary !important;
}

.secondary .p-tooltip-text {
    background: $secondary !important;
    color: white !important;
}

.warning.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $warning !important;
}

.warning.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $warning !important;
}

.warning.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $warning !important;
}

.warning.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $warning !important;
}

.warning .p-tooltip-text {
    background: $warning !important;
    color: black !important;
}

.success.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $success !important;
}

.success.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $success !important;
}

.success.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $success !important;
}

.success.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $success !important;
}

.success .p-tooltip-text {
    background: $success !important;
    color: white !important;
}

.info.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $info !important;
}

.info.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $info !important;
}

.info.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $info !important;
}

.info.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $info !important;
}

.info .p-tooltip-text {
    background: $info !important;
    color: white !important;
}
.primary.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $primary !important;
}

.primary.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $primary !important;
}

.primary.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $primary !important;
}

.primary.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $primary !important;
}

.primary .p-tooltip-text {
    background: $primary !important;
    color: white !important;
}

.danger.p-tooltip-top .p-tooltip-arrow {
    border-top-color: $danger !important;
}

.danger.p-tooltip-right .p-tooltip-arrow {
    border-right-color: $danger !important;
}

.danger.p-tooltip-left .p-tooltip-arrow {
    border-left-color: $danger !important;
}

.danger.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: $danger !important;
}

.danger .p-tooltip-text {
    background: $danger !important;
    color: white !important;
}

ngb-tooltip-window.info.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: $info !important;
}

ngb-tooltip-window.info.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: $info !important;
}

ngb-tooltip-window.info.bs-tooltip-right .tooltip-arrow:before {
    border-right-color: $info !important;
}

ngb-tooltip-window.info.bs-tooltip-left .tooltip-arrow:before {
    border-left-color: $info !important;
}

ngb-tooltip-window.info .tooltip-inner {
    background-color: $info !important;
}

ngb-tooltip-window.danger.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: $danger !important;
}

ngb-tooltip-window.danger.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: $danger !important;
}

ngb-tooltip-window.danger.bs-tooltip-right .tooltip-arrow:before {
    border-right-color: $danger !important;
}

ngb-tooltip-window.danger.bs-tooltip-left .tooltip-arrow:before {
    border-left-color: $danger !important;
}

ngb-tooltip-window.danger .tooltip-inner {
    background-color: $danger !important;
}

ngb-tooltip-window.primary.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: $primary !important;
}

ngb-tooltip-window.primary.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: $primary !important;
}

ngb-tooltip-window.primary.bs-tooltip-right .tooltip-arrow:before {
    border-right-color: $primary !important;
}

ngb-tooltip-window.primary.bs-tooltip-left .tooltip-arrow:before {
    border-left-color: $primary !important;
}

ngb-tooltip-window.primary .tooltip-inner {
    background-color: $primary !important;
}

ngb-tooltip-window.success.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: $success !important;
}

ngb-tooltip-window.success.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: $success !important;
}

ngb-tooltip-window.success.bs-tooltip-right .tooltip-arrow:before {
    border-right-color: $success !important;
}

ngb-tooltip-window.success.bs-tooltip-left .tooltip-arrow:before {
    border-left-color: $success !important;
}

ngb-tooltip-window.success .tooltip-inner {
    background-color: $success !important;
}

ngb-tooltip-window.warning.bs-tooltip-bottom .tooltip-arrow:before {
    border-bottom-color: $warning !important;
}

ngb-tooltip-window.warning.bs-tooltip-top .tooltip-arrow:before {
    border-top-color: $warning !important;
}

ngb-tooltip-window.warning.bs-tooltip-right .tooltip-arrow:before {
    border-right-color: $warning !important;
}

ngb-tooltip-window.warning.bs-tooltip-left .tooltip-arrow:before {
    border-left-color: $warning !important;
}

ngb-tooltip-window.warning .tooltip-inner {
    background-color: $warning !important;
    color: black;
}


.item-img {
    padding-bottom: 1.5rem !important;
}

.p-carousel-items-content {
    height: 100px !important;
}

.borderRight {
    border-right: 1px solid #ccc;
}

@media screen and (max-width: 769px)  {
    .borderRight {
        border-right: 0;
    }
}

.callNum {
    border: 1px solid #ccc;
    border-radius: 6px;
    padding: 0.25rem;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.form-control:focus {
    color: #212529;
    background-color: #ffffff;
    border-color: tint-color($primary,40%);
    outline: 0;
    box-shadow: none !important;
}

.p-inputtext:enabled:hover {
    border-color: tint-color($primary,40%) !important;
}

.p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    border-color: tint-color($primary,40%) !important;
}

.p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none !important;
    border-color: tint-color($primary,40%) !important;
}

.p-multiselect:not(.p-disabled):hover {
    border-color: tint-color($primary,40%) !important;
}

.p-multiselect {
    border-radius: var(--bs-border-radius);
}

.p-dropdown:not(.p-disabled):hover, .form-control:hover, .p-inputwrapper .p-chips.p-input-wrapper:hover, .p-chips.p-focus.p-inputwrapper-focus {
    border-color: tint-color($primary,40%) !important;
}
.p-chips:not(.p-disabled):hover .p-chips-multiple-container, .p-chips.p-focus.p-inputwrapper-focus .p-chips-multiple-container {
    border-color: tint-color($primary,40%) !important;
}

.bottom-right-1 {
    border-right: 1px solid #ccc;
}

.p-button:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
}

.p-editor-container .p-editor-content .ql-editor {
    text-align: start;
}

.ql-editor p {
    font-family: "Almarai", sans-serif !important;
    font-size: 15px;
    font-weight: 500;
}

.ql-editor {
    transition: all 0.5s ease;
}

.ql-editor:focus {
    box-shadow: 0 0 0 0.15rem rgba(33, 150, 243, 0.25) !important;
    border-radius: 0 0 6px 6px;
    border-color: $primary;
}

.breadcrumb-item+.breadcrumb-item::before {
    float: right;
    padding-left: .5rem;
    color: #677591;
    content: "/";
}

.breadcrumb .breadcrumb-item:first-child {
    margin-left: 0px !important;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;
    display: inline-block;
    padding-right: 0.5rem;
}

p-columnfilter.p-element {
    border-radius: 50%;
}

em {
    font-style: normal;
}

p-dropdown.ng-dirty.ng-invalid>.p-dropdown {
    border-color: $danger !important;
}

.p-dropdown .p-icon-wrapper .p-dropdown-clear-icon {
    color: #677591;
    right: unset;
    left: 2.357rem;
}
.p-dropdown .p-dropdown-label {
    padding-right: 0.5rem !important;
    padding-left: 1.5rem;
}
.p-chip .p-chip-text {
    line-height: 1.5;
    margin: 0.25rem;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    padding: .3rem .5rem;
    background: #dee2e6;
    color: #495057;
    border-radius: 16px;
}

.p-inputwrapper .p-chips .p-chips-multiple-container {
    border-radius: var(--bs-border-radius);
    padding: .375rem .75rem;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    height: 42px;
    margin-bottom: 0;
    box-shadow: none;
}

.form-control:active {
    box-shadow: 0 0 0 0.1rem rgba(33, 150, 243, 0.25);
}

.font-15 {
    font-size: 15px;
}

.p-overlaypanel {
    width: 450px
}

.p-overlaypanel-content {
    width: auto;
    max-height: 650px;
    overflow-y: auto;
}

@media (max-width: 767px) {
    .p-overlaypanel {
        width: 90%;
    }
}

.inputFile {
    line-height: 2;
}

input::placeholder {
    line-height: 38px !important;
    height: 38px !important;
}

.form-control.ng-invalid.ng-dirty {
    border: 1px solid $danger !important;
}

.custom-dropzone.ng-invalid {
    border: 1px solid $danger !important;
}

quill-editor.ng-invalid.ng-dirty{
    border: 1px solid red;
    border-radius: 6px;
}

.p-accordion-header-link {
    color: inherit;
    background: none;
    border: none;
}

.p-accordion-toggle-icon {
    margin-right: 0;
    margin-left: 0.5rem;
    rotate: 180deg;
    display: none;
}

.p-accordion p-accordiontab .p-accordion-content {
    border: none;
}

.p-button-icon-only:after {
    display: none;
}

.badge {
    display: inline-flex;
    align-items: center;
    font-weight: 200;
} 

.login_page {
    margin: 7rem 0 !important;
}

div.p-tabview-nav-content li:not(.p-highlight) a {
        color: inherit !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: white;
    border-color: $primary;
    color: $primary;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: white;
    border-color: white;
    padding: 0.5rem;
    margin: 0 0 0 .5rem;
    padding-bottom: 0.75rem;
}

.input-group.ng-invalid {
    border: 1px solid $danger;
    border-radius: var(--bs-border-radius);
}

.p-dropdown {
    border-radius: var(--bs-border-radius);
    width: 100%;
    min-height: 42px;
    border: var(--bs-border-width) solid var(--bs-border-color);
    span {
        line-height: 1.5;
    }
}

.star {
    position: relative;
    display: inline-block;
    font-size: 1.3rem;
    color: #d3d3d3;
}

.full {
    color: $warning;
}

.half {
    position: absolute;
    display: inline-block;
    overflow: hidden;
    color: $warning;
}

p-carousel {
    .p-carousel-indicators {
        .p-carousel-indicator button {
            background-color: tint-color($info, 50%);
            width: 2rem;
            height: .5rem;
            transition: background-color .2s, color .2s, box-shadow .2s;
            border-radius: 0;
            opacity: 0.5;
        }
        .p-carousel-indicator.p-highlight button, .p-galleria .p-galleria-indicators .p-galleria-indicator.p-highlight button {
            background-color: $info !important;
            opacity: 1;
        }
    }
}

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a.active {
    color: tint-color($primary, 20%) !important;
}

.ql-editor ol, .ql-editor ul {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.ql-editor li:not(.ql-direction-rtl):before {
    margin-right: -1.5em !important;
    margin-left: .3em !important;
    text-align: right !important;
}

/* Ribbon top left*/

.ribbon {
    width: 140px;
    height: 140px;
    overflow: hidden;
    position: absolute;
    border-radius: 18px;
}

.ribbon span {
    padding: 30px 20px 10px 0 !important;
    font-size: 14px !important;
    right: -12px !important;
    top: -10px !important;
    position: absolute;
    display: block;
    width: 245px;
    box-shadow: 0 2px 5px #0000001a;
    color: #fff;
    font-weight: 200;
    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
    text-align: center;
    padding-right: 20px;
}
.ribbon-top-left {
    top: 0px;
    left: 0px;
}

.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.ribbon-top-left::before {
    top: 0;
    right: 0;
}
.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}
.ribbon-top-left span {
    right: -25px;
    top: 15px;
    transform: rotate(-45deg);
}

.ribbon-border-success + div {
    border: 1px solid var(--bs-success);
}

.ribbon-border-warning + div {
    border: 1px solid var(--bs-warning);
}

.ribbon-border-danger + div {
    border: 1px solid var(--bs-danger);
}

.ribbon-border-primary + div {
    border: 1px solid var(--bs-primary);
}

.toast-success {
    background-color: $success;
}

.toast-info {
    background-color: $info;
}

.toast-warning {
    background-color: $warning;
}

.toast-error {
    background-color: $danger;
}

.p-inline-message {
    margin-bottom: 0.5rem;
}

.iti__country-list {
    z-index: 1000;
    max-width: 400px;
}

.callComments {
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 200px;
}

.p-inplace .p-inplace-display {
    display: inline-block;
    padding: 0;
}

.p-inplace .p-inplace-display:not(.p-disabled):hover {
    background: none !important;
    color: #495057;
}

.p-inplace .p-inplace-display:not(.p-disabled) span:hover {
    background-color: #F2F4F8 !important;
    color: #495057;
}

.p-datatable.p-datatable-sm .p-datatable-header {
    border-radius: $radius $radius 0 0; 
}

.p-datatable>.p-datatable-wrapper:first-child {
    border-radius: $radius $radius 0 0; 
}

.p-datatable>.p-datatable-wrapper {
    border: 1px solid #e9ecef;
    border-width: 0 1px 0 1px;
}

.p-datatable p-paginator:last-child .p-paginator-bottom {
    border-radius: 0 0 $radius $radius;
}

.p-datatable .p-datatable-footer {
    border-radius: 0 0 $radius $radius;
}

.topTelesales {
    border-radius: var(--bs-border-radius-lg);
    box-shadow: 0 5px 15px #0000000d;
    .stats {
        padding: 0.5rem 1.5rem;
        border-radius: 20px;
        margin: 1rem;
    }
    .topSalesImg {
        width: 80px;
        height: 80px;
        border: 5px solid white;
        border-radius: 50%;
    }
    .topSalesName {
        margin-right: 125px;
    }
}

.text-divider{
    margin: 2em 0; 
    line-height: 0 !important; 
    text-align: center;
}
.text-divider span{
    background-color: white; 
    padding: 1em;
}
.text-divider:before{ 
    content: " "; 
    display: block; 
    border-top: 1px dashed #e3e3e3; 
}

.p-tabview .p-tabview-panels {
    background: $bg-color;
}

.p-tabview .p-tabview-nav {
    background: white;
    border: none;
    padding: 1rem;
    border-radius: 8px;
    margin: 0;
}

.fa, .fa-brands, .fa-classic, .fa-regular, .fa-sharp, .fa-solid, .fab, .far, .fas {
    line-height: normal !important;
}

.description p { 
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.descriptionNoMargin p { 
    margin: 0;
}

.profileLink {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    border-radius: 6px;
}

.activeProfileLink ,.profileLink:hover {
    background: rgba(0, 0, 0, 0.04);
}

// Date range 
.dp-hidden {
    width: 0;
    margin: 0;
    border: none;
    padding: 0;
}
.custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
}
.custom-day.focused {
    background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
    background-color: $primary;
    color: white;
}
.custom-day.faded {
    background-color: tint-color($primary,40%);
}
.form-select {
    background-position: left .75rem center;
}
