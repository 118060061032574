

button:not(:disabled) {
    outline: none;
}


.breadcrumb {
    letter-spacing: 0.5px;
    padding: 8px 24px;
    display: block;
}

.breadcrumb .breadcrumb-item a {
    color: #58595B;
}

.breadcrumb .breadcrumb-item a:hover {
    color: var(--bs-primary);
}

.breadcrumb .breadcrumb-item.active {
    color: var(--bs-primary);
}

.breadcrumb .breadcrumb-item:last-child:after {
    display: none;
}

body {
    font-family: 'Almarai', sans-serif;
    overflow-x: hidden !important;
    font-size: 16px;
    color: #161c2d;
    text-align: right;
}


html {
    direction: rtl;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: "Almarai", sans-serif;
    line-height: var(--bs-body-line-height) !important;
    font-weight: 600;
}

a {
    text-decoration: none !important;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

p {
    line-height: 1.6;
}

.section {
    padding: 100px 0;
    position: relative;
}

.section-two {
    padding: 60px 0;
    position: relative;
}


.title-heading {
    line-height: 26px;
}

.title-heading .heading {
    font-size: 45px;
    letter-spacing: 1px;
}

.title-heading .para-desc {
    font-size: 18px;
}

.section-title {
    position: relative;
}

.section-title .title {
    font-size: 36px;
    font-weight: 700;
}

.text-shadow-title {
    text-shadow: 2px 0 0 #ffffff, -2px 0 0 #ffffff, 0 4px 0 rgba(255, 255, 255, 0.4), 0 -2px 0 #ffffff, 1px 1px #ffffff, -1px -1px 0 #ffffff, 1px -1px 0 #ffffff, -1px 1px 0 #ffffff;
}

@media (max-width: 768px) {
    .page-next-level .page-next {
        top: 70px;
    }
    .section {
        padding: 60px 0;
    }

    .title-heading .heading {
        font-size: 35px !important;
    }

    .title-heading .para-desc {
        font-size: 15px;
    }

    .section-title .title {
        font-size: 24px;
    }

    .mt-60 {
        margin-top: 60px;
    }
}

#topnav .logo {
    float: right;
    color: #58595B !important;
}

#topnav {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 1030;
    background-color: transparent;
    border: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}

#topnav .logo .l-dark {
    display: none;
}

#topnav .logo .l-light {
    display: inline-block;
}

#topnav .l-dark {
    display: none;
}

#topnav .l-light {
    display: inline-block;
}

#topnav .has-submenu.active a {
    color: #ffffff;
}

#topnav .has-submenu.active .submenu li.active > a {
    color: var(--bs-primary) !important;
}

#topnav .has-submenu.active.active .menu-arrow {
    border-color: var(--bs-primary);
}

#topnav .has-submenu {
    position: relative;
}

#topnav .has-submenu .submenu .has-submenu:hover .submenu-arrow {
    border-color: var(--bs-primary);
}

#topnav .navbar-toggle {
    background: none;
    border: 0;
    position: relative;
    max-width: 60px;
    padding: 0;
    margin: 0;
    cursor: pointer;
}

#topnav .navbar-toggle .lines {
    width: 25px;
    display: block;
    position: relative;
    margin: 28px 20px;
    height: 18px;
}

#topnav .navbar-toggle span {
    height: 2px;
    width: 100%;
    background-color: #58595B;
    display: block;
    margin-bottom: 5px;
    -webkit-transition: -webkit-transform .5s ease;
    transition: -webkit-transform .5s ease;
    transition: transform .5s ease;
    transition: transform .5s ease, -webkit-transform .5s ease;
}

#topnav .navbar-toggle span:last-child {
    margin-bottom: 0;
}

#topnav .buy-button .login-btn-primary {
    display: none;
}

#topnav .buy-button .login-btn-light {
    display: inline-block;
}

#topnav .navbar-toggle.open span {
    position: absolute;
}

#topnav .navbar-toggle.open span:first-child {
    top: 6px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

#topnav .navbar-toggle.open span:nth-child(2) {
    visibility: hidden;
}

#topnav .navbar-toggle.open span:last-child {
    width: 100%;
    top: 6px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

#topnav .navbar-toggle.open span:hover {
    background-color: var(--bs-primary);
}

#topnav .navbar-toggle:hover,
#topnav .navbar-toggle .navigation-menu > li > a:hover,
#topnav .navbar-toggle:focus {
    background-color: transparent;
}

#topnav .navigation-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
}

#topnav .navigation-menu > li {
    float: left;
    display: block;
    position: relative;
    margin: 0 10px;
}

#topnav .navigation-menu > li > a {
    display: block;
    color: #58595B;
    font-size: 18px;
    background-color: transparent !important;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 24px;
    text-transform: uppercase;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    font-family: "Almarai", sans-serif;
    padding-left: 15px;
    padding-right: 15px;
}

#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
    color: var(--bs-primary);
}

#topnav .navigation-menu .has-submenu:hover .menu-arrow {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}

#topnav .menu-extras {
    float: right;
}

#topnav.scroll {
    background-color: #ffffff;
    border: none;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.scroll .navigation-menu > li > a {
    color: #58595B;
}

#topnav.scroll .navigation-menu > li > .menu-arrow {
    border-color: #58595B;
}

#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
    color: var(--bs-primary);
}

#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
    border-color: var(--bs-primary);
}

#topnav.defaultscroll.dark-menubar .logo {
    line-height: 70px;
}

#topnav.defaultscroll.scroll .logo {
    line-height: 62px;
}

#topnav.defaultscroll.scroll.dark-menubar .logo {
    line-height: 62px;
}

#topnav.nav-sticky {
    background: #ffffff;
    -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
}

#topnav.nav-sticky .navigation-menu.nav-light > li > a {
    color: #58595B;
}

#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: var(--bs-primary) !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
    border-color: var(--bs-primary) !important;
}

#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
    color: var(--bs-primary) !important;
}

#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: #58595B;
}

#topnav.nav-sticky .buy-button .login-btn-primary {
    display: inline-block;
}

#topnav.nav-sticky .buy-button .login-btn-light {
    display: none;
}

#topnav.nav-sticky .logo .l-dark {
    display: inline-block;
}

#topnav.nav-sticky .logo .l-light {
    display: none;
}

#topnav.nav-sticky .l-dark {
    display: inline-block;
}

#topnav.nav-sticky .l-light {
    display: none;
}

.logo {
    font-weight: 700;
    font-size: 24px;
    padding: 0 0 6px;
    letter-spacing: 1px;
    line-height: 68px;
}

@media (min-width: 992px) {
    #topnav .navigation-menu {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
    }

    #topnav .navigation-menu > .has-submenu > .submenu > .has-submenu > .submenu::before {
        top: 20px;
        margin-left: -35px;
        -webkit-box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
        box-shadow: 1px 1px 0px 0px rgba(60, 72, 88, 0.15);
        border-color: transparent #ffffff #ffffff transparent;
    }

    #topnav .navigation-menu > .has-submenu:hover .menu-arrow {
        top: 33px !important;
    }

    .has-submenu:hover .menu-arrow {
        top: 33px !important;
    }

    #topnav .navigation-menu > .has-submenu.active .menu-arrow {
        top: 30px;
    }

    .has-submenu.active .menu-arrow {
        top: 30px;
    }

    #topnav .navigation-menu > li .submenu {
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 1000;
        padding: 15px 0;
        list-style: none;
        min-width: 180px;
        visibility: hidden;
        opacity: 0;
        margin-top: 10px;
        -webkit-transition: all .2s ease;
        transition: all .2s ease;
        border-radius: 6px;
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
    }

    #topnav .navigation-menu > li .submenu:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 45px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 7px solid #495057;
        border-color: transparent transparent #ffffff #ffffff;
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        -webkit-box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
        box-shadow: -2px 2px 2px -1px rgba(60, 72, 88, 0.15);
    }

    #topnav .navigation-menu > li .submenu li {
        position: relative;
    }

    #topnav .navigation-menu > li .submenu li a {
        display: block;
        padding: 10px 20px;
        clear: both;
        white-space: nowrap;
        font-size: 11px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: #58595B !important;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    #topnav .navigation-menu > li .submenu li a:hover {
        color: var(--bs-primary) !important;
    }

    #topnav .navigation-menu > li .submenu li ul {
        list-style: none;
        padding-left: 0;
        margin: 0;
    }

    #topnav .navigation-menu > li .submenu.megamenu {
        white-space: nowrap;
        width: auto;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li {
        overflow: hidden;
        min-width: 180px;
        display: inline-block;
        vertical-align: top;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
        left: 100%;
        top: 0;
        margin-left: 10px;
        margin-top: -1px;
    }

    #topnav .navigation-menu > li .submenu > li .submenu {
        left: 106%;
        top: 0;
        margin-left: 10px;
        margin-top: -1px;
    }

    #topnav .navigation-menu > li > a {
        padding-top: 25px;
        padding-bottom: 25px;
        min-height: 62px;
    }

    #topnav .navigation-menu > li:hover > .menu-arrow {
        border-color: var(--bs-primary);
    }

    #topnav .navigation-menu > li:hover > a,
    #topnav .navigation-menu > li.active > a {
        color: var(--bs-primary) !important;
    }

    #topnav .navigation-menu > li.last-elements .submenu {
        left: auto;
        right: 0;
    }

    #topnav .navigation-menu > li.last-elements .submenu:before {
        left: auto;
        right: 10px;
    }

    #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
        left: auto;
        right: 100%;
        margin-left: 0;
        margin-right: 10px;
    }

    #topnav .navigation-menu.nav-light > li > a {
        color: #ffffffc9;
    }

    #topnav .navigation-menu.nav-light > li.active > a {
        color: #ffffff !important;
    }

    #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
        border-color: #ffffff !important;
    }

    #topnav .navigation-menu.nav-light > li:hover > a {
        color: #ffffff !important;
    }

    #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
        border-color: rgba(255, 255, 255, 0.5);
    }

    #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
        border-color: #ffffff !important;
    }

    #topnav .navigation-menu.nav-right {
        -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
    }

    #topnav .navigation-menu.nav-left {
        -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
    }

    #topnav .navigation-menu.nav-left > li.last-elements .submenu {
        left: 0 !important;
        right: auto !important;
    }

    #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
        left: 45px !important;
        right: auto !important;
    }

    #topnav .buy-button {
        padding-left: 15px;
        margin-left: 15px;
    }

    #topnav .navbar-toggle {
        display: none;
    }

    #topnav #navigation {
        display: block !important;
    }

    #topnav.scroll {
        top: 0;
    }

    #topnav.scroll .navigation-menu > li > a {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    #topnav.scroll-active .navigation-menu > li > a {
        padding-top: 25px;
        padding-bottom: 25px;
    }
}

@media (max-width: 991px) {
    #topnav {
        background-color: #ffffff;
        -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
        min-height: 74px;
    }

    #topnav .logo .l-dark {
        display: inline-block !important;
    }

    #topnav .logo .l-light {
        display: none !important;
    }

    #topnav .l-dark {
        display: inline-block !important;
    }

    #topnav .l-light {
        display: none !important;
    }

    #topnav .container {
        width: auto;
    }

    #topnav #navigation {
        max-height: 400px;
    }

    #topnav .navigation-menu {
        float: none;
    }

    #topnav .navigation-menu > li {
        float: none;
    }

    #topnav .navigation-menu > li .submenu {
        display: none;
        list-style: none;
        padding-left: 20px;
        margin: 0;
    }

    #topnav .navigation-menu > li .submenu li a {
        display: block;
        position: relative;
        padding: 7px 15px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.04em;
        font-weight: 700;
        color: #58595B !important;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }

    #topnav .navigation-menu > li .submenu.open {
        display: block;
    }

    #topnav .navigation-menu > li .submenu .submenu {
        display: none;
        list-style: none;
    }

    #topnav .navigation-menu > li .submenu .submenu.open {
        display: block;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li > ul {
        list-style: none;
        padding-left: 0;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
        display: block;
        position: relative;
        padding: 10px 15px;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 2px;
        color: #8492a6;
    }

    #topnav .navigation-menu > li > a {
        color: #58595B;
        padding: 10px 20px;
    }

    #topnav .navigation-menu > li > a:after {
        position: absolute;
        right: 15px;
    }

    #topnav .navigation-menu > li > a:hover,
    #topnav .navigation-menu > li .submenu li a:hover,
    #topnav .navigation-menu > li.has-submenu.open > a {
        color: var(--bs-primary);
    }

    #topnav .menu-extras .menu-item {
        border-color: #8492a6;
    }

    #topnav .navbar-header {
        float: left;
    }

    #topnav .buy-button .login-btn-primary {
        display: inline-block !important;
    }

    #topnav .buy-button .login-btn-light {
        display: none;
    }

    #topnav .has-submenu .submenu .submenu-arrow {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        right: 20px;
        top: 12px;
    }

    #topnav .has-submenu.active a {
        color: var(--bs-primary);
    }

    #navigation {
        position: absolute;
        top: 74px;
        left: 0;
        width: 100%;
        display: none;
        height: auto;
        padding-bottom: 0;
        overflow: auto;
        border-top: 1px solid #f1f3f9;
        border-bottom: 1px solid #f1f3f9;
        background-color: #ffffff;
    }

    #navigation.open {
        display: block;
        overflow-y: auto;
    }
}

@media (max-width: 768px) {
    #topnav .navigation-menu .has-submenu .menu-arrow {
        right: 16px;
        top: 16px;
    }

    .has-submenu .menu-arrow {
        right: 16px;
        top: 16px;
    }

    #topnav .buy-button {
        padding: 3px 0;
    }
}

@media (min-width: 768px) {
    #topnav .navigation-menu > li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-top: 0;
    }

    #topnav .navigation-menu > li.has-submenu:hover > .submenu > li.has-submenu:hover > .submenu {
        visibility: visible;
        opacity: 1;
        margin-left: 0;
        margin-right: 0;
    }

    .navbar-toggle {
        display: block;
    }
}

@media (max-width: 441px) {
    #topnav .buy-button {
        display: none;
    }

    #topnav .sub-button {
        display: inline-block !important;
    }

    #topnav .menu-extras {
      float: left;
    }

    #topnav .buy-menu-btn {
        display: block !important;
        margin: 0 10px;
        padding: 10px 20px;
    }

    #topnav .navbar-toggle .lines {
        margin-right: 0 !important;
    }
}

#topnav .has-submenu .submenu .submenu-arrow {
    border-width: 2px 0px 0px 2px;
    left: 20px;
    right: auto;
    border: solid #58595B;
    border-radius: 0.5px;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    top: 14px;
}

#topnav .buy-button {
    float: left;
    padding: 0 0 6px;
    line-height: 68px;
}

#topnav .sub-button {
    float: left;
    padding: 0 0 6px;
    line-height: 68px;
}

#topnav .navigation-menu .has-submenu .menu-arrow {
    left: -1px;
    right: auto;
    border: solid #58595B;
    border-radius: 0.5px;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    top: 30px;
}

.has-submenu .menu-arrow {
    left: -1px;
    right: auto;
}

@media (min-width: 992px) {
    #topnav .navigation-menu > .has-submenu > .submenu > .has-submenu > .submenu::before {
        margin-right: -15px;
        margin-left: 0;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }

    #topnav .navigation-menu > li .submenu {
        right: 0;
        left: auto;
    }

    #topnav .navigation-menu > li .submenu:before {
        right: 11px;
        left: auto;
    }

    #topnav .navigation-menu > li .submenu li ul {
        padding-right: 0;
        padding-left: auto;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
        right: 100%;
        margin-right: 10px;
    }

    #topnav .navigation-menu > li .submenu > li .submenu {
        right: 106%;
        left: auto;
        margin-right: 10px;
    }

    #topnav .navigation-menu > li.last-elements .submenu {
        right: auto;
        left: 0;
    }

    #topnav .navigation-menu > li.last-elements .submenu:before {
        right: auto;
        left: 35px;
    }

    #topnav .navigation-menu > li.last-elements .submenu > li.has-submenu .submenu {
        right: auto;
        left: 100%;
        margin-right: 0;
        margin-left: 10px;
    }

    #topnav .navigation-menu.nav-left > li.last-elements .submenu {
        left: auto !important;
        right: 0 !important;
    }

    #topnav .navigation-menu.nav-left > li.last-elements .submenu:before {
        left: auto !important;
        right: 11px !important;
    }

    #topnav .buy-button {
        padding-right: 15px !important;
        margin-right: 15px !important;
        padding-left: auto;
        margin-left: auto;
    }
}

@media (max-width: 991px) {
    #topnav .navigation-menu > li .submenu {
        padding-right: 20px;
    }

    #topnav .navigation-menu > li .submenu.megamenu > li > ul {
        padding-right: 0;
        padding-left: auto;
    }

    #topnav .has-submenu .submenu .submenu-arrow {
        left: 20px;
        right: auto;
    }

    #navigation {
        right: 0;
        left: auto;
    }
}

@media (max-width: 768px) {
    #topnav .navigation-menu .has-submenu .menu-arrow {
        left: 16px;
        right: auto;
    }

    .has-submenu .menu-arrow {
        left: 16px;
        right: auto;
    }

    #topnav .navigation-menu .has-submenu .submenu .submenu-arrow {
        border-width: 0px 2px 2px 0px;
    }
}

@media (max-width: 375px) {
    #topnav .navbar-toggle .lines {
        margin-left: 0 !important;
        margin-right: auto;
    }
}

.bg-home {
    height: 100vh;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.bg-half {
    padding: 184px 0 90px;
    background-size: cover;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
    position: relative;
    background-position: center center;
}

.page-next-level .title {
    font-size: 28px;
    letter-spacing: 1px;
}

.page-next-level .page-next {
    position: relative;
    top: 110px;
    z-index: 99;
}

.footer {
    background: #202942;
    padding: 60px 0;
    position: relative;
    color: #adb5bd;
}

.footer .logo-footer {
    font-size: 22px;
}

.footer .footer-head {
    font-size: 20px;
    letter-spacing: 1px;
    font-weight: 500;
}

.footer .foot-subscribe .form-control {
    background-color: #27314f;
    border: 1px solid #27314f;
    color: #f8f9fc;
}

.footer .foot-subscribe .form-control:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.footer .foot-subscribe.foot-white .form-control {
    color: #adb5bd;
}

.footer .foot-subscribe input::-webkit-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input:-ms-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input::-ms-input-placeholder {
    color: #adb5bd;
}

.footer .foot-subscribe input::placeholder {
    color: #adb5bd;
}

.footer .text-foot {
    color: #adb5bd;
}

.footer .footer-list {
    margin-bottom: 0;
}

.footer .footer-list li {
    margin-bottom: 10px;
}

.footer .footer-list li a {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.footer .footer-list li a:hover {
    color: var(--bs-primary);
}

.footer .footer-list li:last-child {
    margin-bottom: 0;
}

.footer.footer-bar {
    padding: 10px 0;
    border-top: 1px solid #656870;
}

.back-to-top .icons {
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.back-to-top:hover {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.back-to-top:hover .icons {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

.back-to-top {
    left: 30px;
    right: auto;
    z-index: 99;
    position: fixed;
    bottom: 55px;
    display: none;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

.custom-form {
    .form-group label {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0.25rem;
    }

    .form-group .form-control {
        -webkit-box-shadow: none;
        box-shadow: none;
        background-color: #ffffff;
        border: 1px solid #dee2e6;
        color: #58595B;
        height: 42px;
        font-size: 13px;
        border-radius: 6px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
    }

    .form-group .form-control:focus,
    .form-group .form-control.active {
        border-color: $primary;
    }

    .form-group textarea {
        height: 120px !important;
    }

    .form-group textarea.form-control {
        line-height: 25px;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background-color: transparent;
        opacity: 1;
    }

    .custom-control-input:checked ~ .custom-control-label:before {
        color: #ffffff;
        border-color: $primary;
        background-color: $primary;
    }

    .custom-control-input:focus ~ .custom-control-label::before,
    .form-control:focus {
        -webkit-box-shadow: none;
        box-shadow: none;
        border-color: $primary;
    }

    .form-group .icons {
        position: absolute;
        top: 47px;
        left: 18px;
        right: 18px;
        
    }

    .form-group .password {
        position: absolute;
        top: 50px;
        left: 10px;
        right: unset;
        font-size: 13px;
    }

    .input-group > .custom-select:not(:last-child),
    .input-group > .form-control:not(:last-child) {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .input-group > .form-control:not(:last-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .form-group {
        text-align: right;
        margin-bottom: 20px;
    }

    .form-check {
        display: block;
        min-height: 1.5rem;
        padding-right: 1.5em !important;
        margin-bottom: .125rem;
        padding-left: 0 !important;
    }

    .form-check-inline {
        display: inline-block;
        margin-left: 1rem !important;
        margin-right: 0 !important;
    }

    .form-check .form-check-input {
        float: right !important;
        margin-right: -1.5em !important;
        margin-left: 0 !important;
    }
}

.avatar.avatar-small {
    height: 65px;
    width: 65px;
}

.back-to-home {
    left: 2%;
    right: auto;
    position: absolute;
    top: 4%;
    z-index: 1;
}

.accordion-button:after {
    margin-right: auto;
    margin-left: 0 !important;
}

p-paginator {
    button.p-paginator-first, button.p-paginator-prev, button.p-paginator-next, button.p-paginator-last {
        transform: rotate(180deg);
    }
}

p-carousel.home {
    .p-carousel-items-content {
        direction: rtl;
    }
    .p-carousel-indicators {
        direction: rtl;
        .p-carousel-indicator {
            margin-right: 0;
            margin-bottom: 0;
        }
        li.p-carousel-indicator:first-child button {
            border-radius: 0 4px 4px 0;
        }
        li.p-carousel-indicator:last-child button {
            border-radius: 4px 0 0 4px;
        }
    }
}

.form-check .form-check-input {
    float: right !important;
    margin-right: -1.5em;
    margin-left: 0 !important;
}

.form-check-inline {
    display: inline-block;
    margin-left: 1rem;
    margin-right: 0 !important;
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-right: 1.5em;
    padding-left: 0 !important;
    margin-bottom: .125rem;
}

.p-multiselect-panel .p-multiselect-header .p-checkbox {
    margin-left: .5rem;
    margin-right: .0 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item .p-checkbox {
    margin-left: .5rem;
    margin-right: .0 !important;
}

.p-paginator .p-dropdown .p-dropdown-label {
    padding-left: 0;
    padding-right: 0.5rem;
    display: flex;
    align-items: center;
}

.p-inputgroup-addon:last-child {
    border-radius: var(--bs-border-radius) 0 0 var(--bs-border-radius);
    height: 42px;
    padding: 0;
}

.p-inputgroup input:first-child {
    border-radius: 0 var(--bs-border-radius) var(--bs-border-radius) 0;
}

.p-inputgroup .p-dropdown.p-component {
    border-top-left-radius: var(--bs-border-radius);
    border-bottom-left-radius: var(--bs-border-radius);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.p-inputgroup-addon:first-child, ::ng-deep .p-inputgroup button:first-child, ::ng-deep .p-inputgroup input:first-child, ::ng-deep .p-inputgroup > .p-inputwrapper:first-child > .p-component, ::ng-deep .p-inputgroup > .p-inputwrapper:first-child > .p-component > .p-inputtext {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.p-chips .p-chips-multiple-container .p-chips-token {
    margin-right: 0 !important;
    margin-left: .25rem;
}

.p-chips .p-chips-multiple-container .p-chips-token .p-chips-token-icon {
    margin-right: .5rem;
    margin-left: .0 !important;
}

.p-inline-message .p-inline-message-icon {
    font-size: 1rem;
    margin-left: .5rem;
    margin-right: 0 !important;
}

.iti {
    position: relative;
    display: block !important;
    direction: ltr;
}

.iti--allow-dropdown input, .iti--allow-dropdown input[type=text], .iti--allow-dropdown input[type=tel], .iti--separate-dial-code input, .iti--separate-dial-code input[type=text], .iti--separate-dial-code input[type=tel] {
    padding: .375rem .75rem;
    padding-right: 0.75rem;
}

.iti__arrow {
    margin-right: 6px;
    margin-left: 0;
    width: 0;
    height: 0;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-top: 4px solid #555;
}

.iti__selected-flag {
    direction: rtl;
}

.iti--allow-dropdown input.phoneNumber {
    padding-left: 3rem !important;
}

.iti--allow-dropdown input.phoneNumber.is-invalid,  .iti--allow-dropdown input[type=tel].phoneNumber.is-invalid {
    padding-right: calc(1.5em + .75rem) !important;
}
